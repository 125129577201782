import styled from "styled-components";

const Container = styled.div`
  position: relative;
`

const LogoRaven = styled.div`
    background-image: url("./logo.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 173px;
    height: 150px;
  position: absolute;
  left: 10%;
  bottom: -120px;
  @media screen and (max-width: 980px) {
    display: none;
  }
`

const LogoBox = styled.div`
   background-color: #fff;
    background-image: url("./logo_275x80.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
  border-radius: 50%;
  position: absolute;
  right: 10%;
  bottom: -120px;
  @media screen and (max-width: 980px) {
    display: none;
  }
`

export {
    Container,
    LogoBox,
    LogoRaven
}
import {useContext} from 'react';
import {FormControl, MenuItem, Select, InputLabel, Grid, TextField} from '@mui/material'
import {FormContext} from "../context";
import {FieldRequiredForm} from "../validate";

const weights = [
    '46-48 кг', '51 кг', '54 кг', '57 кг', '60 кг', '63,5 кг', '67 кг', '71 кг', '75 кг', '80 кг', '86 кг', '92 кг', '92+ кг'
]

const ContactForm = ({validate}) => {
    const {
        values, setFieldValue
    } = useContext(FormContext)

    const {fieldsError} = validate

    const handlerChange = (e, prop) => {
        setFieldValue(prop, e.target.value)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Фамилия"
                    error={fieldsError?.[FieldRequiredForm.LAST_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.LAST_NAME]}
                    value={values.lastName}
                    onChange={(e) => handlerChange(e, 'lastName')}
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="firstName"
                    error={fieldsError?.[FieldRequiredForm.FIRST_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.FIRST_NAME]}
                    name="firstName"
                    value={values.firstName}
                    onChange={(e) => handlerChange(e, 'firstName')}
                    label="Имя"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="fatherName"
                    name="fatherName"
                    error={fieldsError?.[FieldRequiredForm.FATHER_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.FATHER_NAME]}
                    value={values.fatherName}
                    onChange={(e) => handlerChange(e, 'fatherName')}
                    label="Отчество"
                    fullWidth
                    autoComplete="father-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="level"
                    name="level"
                    value={values.level}
                    onChange={(e) => handlerChange(e, 'level')}
                    label="Спортивный разряд"
                    fullWidth
                    autoComplete="level"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">Весовая каттегория</InputLabel>
                    <Select
                        required
                        error={fieldsError?.[FieldRequiredForm.WEIGHT]}
                        helperText={fieldsError?.[FieldRequiredForm.WEIGHT]}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.weight}
                        label="Весовая каттегория"
                        onChange={(_, {props}) => setFieldValue('weight', props?.value)}
                    >
                        {weights.map((w, index) => <MenuItem key={index} value={w}>{w}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="year"
                    name="year"
                    error={fieldsError?.[FieldRequiredForm.YEAR]}
                    helperText={fieldsError?.[FieldRequiredForm.YEAR]}
                    value={values.year}
                    onChange={(e) => handlerChange(e, 'year')}
                    label="Год рождения"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="city"
                    name="city"
                    value={values.city}
                    onChange={(e) => handlerChange(e, 'city')}
                    label="Город"
                    fullWidth
                    autoComplete="city"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="sport-org"
                    name="sport-org"
                    value={values.sportOrg}
                    onChange={(e) => handlerChange(e, 'sportOrg')}
                    label="Спортиваный клуб (общество)"
                    autoComplete="sport-org"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="couch"
                    name="couch"
                    label="ФИО Тренера"
                    value={values.couch}
                    onChange={(e) => handlerChange(e, 'couch')}
                    fullWidth
                    autoComplete="couch"
                    variant="standard"
                />
            </Grid>
        </Grid>

    );
}

export {
    ContactForm
}
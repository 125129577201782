import {Typography} from "@mui/material";

const Header = ({title, description}) => {
    return (
        <>
            <Typography component="h1" variant="h4" align="center">
                {title}
            </Typography>
            <Typography variant="subtitle1" align="center">
                {description}
            </Typography>
        </>
    )
}

export {
    Header
}
import {Box, Tab} from '@mui/material'
import {TabPanel, TabContext, TabList} from '@mui/lab'
import {styled} from "@mui/material";
import {Participants} from "../participants";
import {Sponsors} from "../sponsors";
import {LogoRaven, LogoBox, Container} from "./style";


const RavensTabList = styled(TabList)({
    '.MuiTabs-indicator': {
        backgroundColor: '#d32f2f'
    }
})

const RavensTab = styled(Tab)({
    color: '#fff',
    '&.Mui-selected': {
        color: '#d32f2f'
    }
})



const Navigator = ({handleChange, defaultValue} = {handleChange: () => null}) => {
    const pathToForm = [
        {
            value: 1,
            label: "Участникам",
            component: <Participants />
        },
        {
            value: 2,
            label: "Спонсорам",
            component: <Sponsors />
        }
    ]

    return (
        <TabContext value={defaultValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Container>
                <RavensTabList centered onChange={handleChange} aria-label="lab API tabs example">
                    {pathToForm.map(({label, value}) =>
                        <RavensTab label={label} value={value} />
                    )}
                </RavensTabList>
                <LogoRaven/>
                {/*<LogoBox/>*/}
                </Container>
            </Box>

            {pathToForm.map(({component, value}) =>
                <TabPanel value={value}>{component}</TabPanel>
            )}
        </TabContext>
    )
}

export {Navigator}
import './App.css';

import {StrictMode, useState, useEffect} from "react";

import {Copyright, Navigator, Video} from "./components";
import {FormProvider} from "./components/participants/context";
import {useQueryParam} from "./hooks";

// TODO в утилиты компоненту ниже
const TabsName = Object.freeze({
    ATHLETES: 1,
    SPONSORS: 2
})

const Tab = Object.freeze({
    [TabsName.ATHLETES]: "athletes",
    [TabsName.SPONSORS]: "sponsors"
})

const InitTab = Object.freeze({
    [Tab[TabsName.ATHLETES]]: TabsName.ATHLETES,
    [Tab[TabsName.SPONSORS]]: TabsName.SPONSORS
})

function App() {
  // TODO унести в отдельный компонент и хук
  const [queryTab, setQueryTab] = useQueryParam('tab')
  const [currentForm, setCurrentForm] = useState(TabsName.ATHLETES)

  const changeCurrentForm = (event, newValue) => {
      setCurrentForm(newValue)
      setQueryTab(Tab[newValue])
  }

  // Запускаем при первой отрисовке для определения актуального таба
  useEffect(() => {
      if(queryTab) {
          setCurrentForm(InitTab[queryTab])
      }
  }, []);


  return (
      <StrictMode>
          <FormProvider>
              <div className="App">
                  <div className="App-Container">
                      <Navigator defaultValue={currentForm} handleChange={changeCurrentForm}/>
                      <Copyright />
                  </div>

                  <Video />
              </div>
          </FormProvider>
      </StrictMode>
  );
}

export default App;

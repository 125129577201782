import {Grid, FormHelperText, TextField, FormControlLabel, Checkbox, Box, Button} from '@mui/material';
import {useFormik} from "formik";

import {FieldRequiredForm, SponsorsFormValidate} from "../../participants/validate";
import {validateStep} from "../../participants/validate/validate";
import sendData from "../../../service/sendData";
import {useState} from "react";

const SponsorsForm = ({setShowThanksPage}) => {
    const [fieldsError, setFieldsError] = useState({})

    const form = useFormik({
        initialValues: {
            phone: '',
            name: '',
            org: '',
            moreInfo: '',
            polit: true
        }
    });
    const {values, setFieldValue} = form

    const handlerChange = (e, prop) => {
        setFieldValue(prop, e.target.value)
    }

    const submitForm = async () => {
        const {fieldsError, isValidate} = validateStep(SponsorsFormValidate, form)

        if(!isValidate) {
            return setFieldsError(fieldsError)
        }

        const data = await sendData('/send.php', values)

        if(data?.isSend) {
            setShowThanksPage(data?.isSend)
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={(e) => handlerChange(e, 'name')}
                        label="Контактное лицо"
                        fullWidth
                        autoComplete="name"
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id="phone"
                        name="phone"
                        error={!!fieldsError?.[FieldRequiredForm.PHONE]}
                        helperText={fieldsError?.[FieldRequiredForm.PHONE]}
                        value={values?.phone}
                        onChange={(e) => handlerChange(e, 'phone')}
                        label="Номер телефона для связи"
                        fullWidth
                        autoComplete="phone"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="org"
                        name="org"
                        value={values.org}
                        onChange={(e) => handlerChange(e, 'org')}
                        label="Организация"
                        fullWidth
                        autoComplete="org"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="moreInfo"
                        name="moreInfo"
                        value={values.moreInfo}
                        onChange={(e) => handlerChange(e, 'moreInfo')}
                        label="Остались вопросы или есть дополнения к выше указанному, оставьте их тут"
                        fullWidth
                        multiline
                        rows={4}
                        autoComplete="moreInfo"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        required
                        onChange={(e) => setFieldValue('polit', e.target.checked)}
                        checked={values.polit}
                        control={<Checkbox color="error" name="polit" value="yes" />}
                        label="Согласен с политикой конфиденциальности"
                    />
                    {fieldsError?.[FieldRequiredForm.POLIT] &&
                        <FormHelperText error>
                            {fieldsError[FieldRequiredForm.POLIT]}
                        </FormHelperText>
                    }
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color="error"
                    variant="contained"
                    onClick={submitForm}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Оставить заявку
                </Button>
            </Box>
        </>
    );
}

export {
    SponsorsForm
}
import Typography from "@mui/material/Typography";

const Thanks = () => {
    return (
        <>
            <Typography align="center" variant="h5" gutterBottom>
                Спасибо за заявку.
            </Typography>
            <Typography align="center" variant="subtitle1">
                В ближайшее время мы свами свяжемся!
            </Typography>
        </>
    )
}

export {Thanks}
import {Grid, FormHelperText, TextField} from '@mui/material';
import {useContext} from "react";
import {FormContext} from "../context/form-context";
import {FieldRequiredForm} from "../validate";

const MoreInfoForm = ({validate}) => {
    const {values, setFieldValue} = useContext(FormContext)
    const {fieldsError} = validate

    const handlerChange = (e, prop) => {
        setFieldValue(prop, e.target.value)
    }

    console.log('!@=>', !!fieldsError?.[FieldRequiredForm.PHONE], fieldsError?.[FieldRequiredForm.PHONE])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="phone"
                        name="phone"
                        error={!!fieldsError?.[FieldRequiredForm.PHONE]}
                        helperText={fieldsError?.[FieldRequiredForm.PHONE]}
                        value={values.phone}
                        onChange={(e) => handlerChange(e, 'phone')}
                        label="Номер телефона для связи"
                        fullWidth
                        autoComplete="phone"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="music"
                        name="music"
                        value={values.music}
                        onChange={(e) => handlerChange(e, 'music')}
                        label="Трек для выхода на ринг"
                        fullWidth
                        helperText="Если нужно"
                        autoComplete="family-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="music-time"
                        name="music-time"
                        value={values.musicTime}
                        onChange={(e) => handlerChange(e, 'musicTime')}
                        label="Точное время песни"
                        fullWidth
                        autoComplete="music-time"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="moreInfo"
                        name="moreInfo"
                        value={values.moreInfo}
                        onChange={(e) => handlerChange(e, 'moreInfo')}
                        label="Остались вопросы или есть дополнения к выше указанному, оставьте их тут"
                        fullWidth
                        multiline
                        rows={4}
                        autoComplete="moreInfo"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export {
    MoreInfoForm
}
import styled from "styled-components";

const FooterWrapper = styled.div`
    width: 300px;
    position: fixed;
    bottom: 20px;
    text-align: center;
    margin-left: -150px;
    left: 50%;
`

export {
    FooterWrapper
}
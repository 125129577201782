import {VideoWrapper} from "./style";

const Video = () => {
    const {innerWidth} = window

    return (
        <VideoWrapper>
            {innerWidth >= 1380 &&
                <video autoPlay loop muted>
                    <source src='./818mcc.mp4' type='video/mp4' />
                </video>
            }
            <div className="overlay"></div>
        </VideoWrapper>
    )
}

export { Video }